"use client"

import { LinearProgress, Stack } from "@mui/material"
import { useSearchQuery } from "@tc/ui-dfe/hooks"
import { signIn } from "next-auth/react"
import { Suspense, useEffect } from "react"
import { externalIdProvider } from "../../src/auth.config"
const ExternalLogin = () => {
  const { query } = useSearchQuery()
  useEffect(() => {
    const callbackUrl = (query?.callbackUrl as string) || "/"
    localStorage.setItem("signin", "/signin")
    signIn(
      "cognito",
      {
        callbackUrl,
      },
      {
        identity_provider: externalIdProvider(),
      },
    )
  }, [])
  return <></>
}

const ExternalSigninPage = () => {
  return (
    <Stack
      sx={{ width: "100%" }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Suspense
        fallback={
          <Stack
            sx={{
              position: "fixed",
              width: "100%",
              top: 0,
              zIndex: (t) => t.zIndex.tooltip,
            }}
          >
            <LinearProgress sx={{ height: 2 }} />
          </Stack>
        }
      >
        <ExternalLogin />
      </Suspense>
    </Stack>
  )
}
export default ExternalSigninPage
