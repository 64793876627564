import {
  SSMClient,
  GetParameterCommand,
  ParameterNotFound,
  GetParameterCommandOutput,
} from "@aws-sdk/client-ssm"
import { env, stage } from "@tc/env-stage"

/**
 * Return anoutput parameter for a given app.
 *
 * `parameterName` must exist, else the function will throw an error.
 */
const ssmCache: Record<string, string> = {}

export const appParameter = async (
  appName: string,
  parameterName: string,
  useCache = true,
): Promise<string> => {
  return await getParameter(env, appName, parameterName, useCache)
}

export const stageParameter = async (
  appName: string,
  parameterName: string,
  useCache = true,
): Promise<string> => {
  return await getParameter(stage, appName, parameterName, useCache)
}

export const getParameter = async (
  myEnv: string,
  appName: string,
  parameterName: string,
  useCache = true,
): Promise<string> => {
  const client = new SSMClient({})
  const name = `/${myEnv}/${appName}/${parameterName}`

  if (useCache && ssmCache[name]) {
    return ssmCache[name]
  }

  const command = new GetParameterCommand({ Name: name })
  let result: GetParameterCommandOutput
  try {
    result = await client.send(command)
  } catch (err) {
    if (err instanceof ParameterNotFound) {
      return Promise.reject(new Error(`Parameter ${name} not found.`))
    } else {
      throw err
    }
  }

  if (result.Parameter && result.Parameter.Value) {
    ssmCache[name] = result.Parameter.Value
    return result.Parameter.Value
  } else {
    return Promise.reject(new Error(`Parameter ${parameterName} not found.`))
  }
}

export const sstParameter = async (
  appName: string,
  parameterName: string,
): Promise<string> => {
  const client = new SSMClient({})
  const name = `/sst/${appName}/${env}/${parameterName}`
  if (ssmCache[name]) {
    return ssmCache[name]
  }

  const command = new GetParameterCommand({ Name: name })
  let result: GetParameterCommandOutput
  try {
    result = await client.send(command)
  } catch (err) {
    if (err instanceof ParameterNotFound) {
      return Promise.reject(new Error(`Parameter ${name} not found.`))
    } else {
      throw err
    }
  }

  if (result.Parameter && result.Parameter.Value) {
    ssmCache[name] = result.Parameter.Value
    return result.Parameter.Value
  } else {
    return Promise.reject(new Error(`Parameter ${parameterName} not found.`))
  }
}
