import { isDev, isDeploymentStage, stage, isTesting1 } from "@tc/env-stage"
import { stageParameter } from "@tc/app-parameter"

enum DfeWebAppDomain {
  dev = "https://dev.tradecert.mpi.govt.nz",
  tst1 = "https://test1.tradecert.mpi.govt.nz",
  tst2 = "https://test2.tradecert.mpi.govt.nz",
  prd = "https://tradecert.mpi.govt.nz",
}

enum DfeB2CLogoutUrl {
  tst2 = "https://b2cst.b2clogin.com/b2cst.onmicrosoft.com/oauth2/v2.0/logout",
}

//b2c policy name
export const b2cPolicyName = "b2c_1a_mpi_010_rp_oidc"
export const mpiWebsite = "https://www.mpi.govt.nz"

/**
 * Redshield domain name for current stage.
 */
export const authDomain = (): string => {
  if (isDeploymentStage(stage)) {
    return DfeWebAppDomain[stage]
  } else {
    throw new Error(`Unrecognised stage ${stage}`)
  }
}

/**
 * Cognito app client id.
 */
export const appClientId = async (): Promise<string> => {
  return stageParameter("cognito", "dfe-client-id")
}

/**
 * Cognito pool id.
 */
export const cognitoPoolId = async (): Promise<string> => {
  return stageParameter("cognito", "pool-id")
}

export const issuer = async (): Promise<string> => {
  return `https://cognito-idp.ap-southeast-2.amazonaws.com/${await cognitoPoolId()}`
}

export const wellKnown = async () => {
  const url = await issuer()
  const response = await fetch(url + "/.well-known/openid-configuration")
  return response.json()
}

export const serverSideLogoutUrl = async () => {
  const wk = await wellKnown()
  let logoutUrl = `${process.env.NEXTAUTH_URL || authDomain()}/signout`
  if (stage === "tst2") {
    const b2cLogoutQuery = `p=${b2cPolicyName}&post_logout_redirect_uri=${mpiWebsite}`
    logoutUrl = `${DfeB2CLogoutUrl.tst2}?${b2cLogoutQuery}`
  }
  const query = new URLSearchParams({
    client_id: await appClientId(),
    logout_uri: logoutUrl,
  })
  return `${wk.end_session_endpoint}?${query}`
}

// found in cognito user pool -> federation -> identity providers
// for MPI Azure AD
export const internalIdp = "mpi-azure-ad"

// for dev/tst1
export const externalIdp = () => {
  if (isDev || isTesting1) {
    // No realme in dev or tst1 yet
    return internalIdp
  } else {
    return "B2c-Sandbox-sysintegrate"
  }
}

// for tst2
export const externalIdpTest2 = "mpi-b2c-nonprod"

export const internalIdProvider = (): string => {
  if (isDeploymentStage(stage)) {
    return internalIdp
  } else {
    throw new Error(`Unrecognised stage ${stage}`)
  }
}
export const externalIdProvider = (): string => {
  if (isDeploymentStage(stage)) {
    return stage === "tst2" ? externalIdpTest2 : externalIdp()
  } else {
    throw new Error(`Unrecognised stage ${stage}`)
  }
}
